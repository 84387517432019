/* transitions.css */
.profile-enter {
    /* Initial state of the profile entering */
  }
  .profile-enter-active {
    /* Final state of the profile entering */
    transition: opacity 300ms, transform 300ms;
  }
  .profile-exit {
    /* Initial state of the profile exiting */
  }
  .profile-exit-active {
    /* Final state of the profile exiting */
    transition: opacity 300ms, transform 300ms;
  }
  
  .question-block-enter {
    /* Initial state of the question block entering */
  }
  .question-block-enter-active {
    /* Final state of the question block entering */
    transition: opacity 300ms;
  }
  .question-block-exit {
    /* Initial state of the question block exiting */
  }
  .question-block-exit-active {
    /* Final state of the question block exiting */
    transition: opacity 300ms;
  }
  
.message-enter {
    opacity: 0;
    transform: translateY(20px);
  }
  .message-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
  }
  .message-exit {
    opacity: 1;
  }
  .message-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
  }
  